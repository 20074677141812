import React from 'react'
import HeaderContainer from '../container/header';
import DocRequirementContainer from '../container/admission/docRequirement';
export default function Registration() {
    return (
        <>
            <HeaderContainer />
            <DocRequirementContainer />
        </>
    )
}
