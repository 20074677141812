export const HOME = '/';
export const SIGN_UP = '/signup';
export const CONTACT = '/contact';
export const SIGN_IN = '/signin';
export const GALLERY = '/gallery';
export const REGISTRATION = '/registration';
export const RESULT = '/result';
export const COURSES = '/courses';
export const ADMISSION = '/admissionform';
export const STUDENT_LOGIN = '/student_login';
export const DASHBOARD = '/dashboard';
